<template>
  <VCard class="pa-10">
    <VCardTitle class="pl-10">
      <h4>{{ strapi.Title }}</h4>
    </VCardTitle>
    <VCardText>
      <VContainer>
        <VForm>
          <label class="mr-5 paragraph mb-1 opacity" for="editAddress">{{ strapi.AddressLabel }}</label>
          <TextField id="editAddress" v-model="address" class="mb-4" type="text" name="address" />
          <label class="mr-5 paragraph mb-1 opacity" for="editZip">{{ strapi.ZipLabel }}</label>
          <TextField id="editZip" v-model="zip" class="mb-4" type="text" name="zip" />
          <label class="mr-5 paragraph mb-1 opacity" for="editCity">{{ strapi.CityLabel }}</label>
          <TextField id="editCity" v-model="city" class="mb-4" type="text" name="city" />
          <label class="mr-5 paragraph mb-1 opacity" for="editCountry">{{ strapi.CountryLabel }}</label>
          <TextField id="editCountry" v-model="country" type="text" name="country" />
        </VForm>
      </VContainer>
    </VCardText>
    <VCardActions class="pr-10 d-flex justify-center">
      <VSpacer />
      <Button variant="outlined" color="secondary" @click="closeModal">Cancel</Button>
      <Button class="bg-secondary" color="white" variant="text" @click="onSave">{{ strapi.SaveButton.Label }}</Button>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'EditDialog',
  props: {
    strapi: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Boolean,
      default: false
    },
    onSave: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      address: '',
      zip: '',
      city: '',
      country: ''
    };
  }
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: -78px;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  overflow-y: auto;
  display: block;
  z-index: 15;
  transition: background-color 0.2s ease-in-out;
  &.-visible {
    background-color: rgba(0, 0, 0, 0.25);
  }
}
.container-wrapper {
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 15;
  transform: translateY(100%);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out 0.2s;
  &.-visible {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
