<template>
  <VStepper v-model="modelValue" class="bg-secondary">
    <VStepperHeader class="bg-secondary">
      <div class="container">
        <div class="v-stepper-section-wrapper d-flex align-center">
          <section class="v-stepper-section-1 d-flex align-center">
            <a href="/" aria-label="Techship home page" class="d-flex w-150px w-md-170px"><Logo alt="Techship Inverse Logo - Click to return to the homepage" inverse /></a>
            <div class="d-flex align-center ml-7">
              <VIcon icon="far fa-arrow-right-from-arc" class="text-primary" size="default" />
              <span class="text-white paragraph-small -no-margin font-weight-bold ml-2">Checkout</span>
            </div>
          </section>
          <section class="v-stepper-section-2">
            <VStepperItem v-for="step in steps" :key="step.value" :title="step.label" :value="step.value" color="primary" :ripple="false" :complete="step.completed" :editable="step.completed" :disabled="isOnLastStep && step.value != 3" />
          </section>
          <section class="v-stepper-section-3">
            <Button v-if="!isOnLastStep" iconButton href="/" color="text-white" class="bg-secondary-dark">
              Cancel Checkout
              <VIcon icon="fas fa-times" class="text-primary ml-2" size="default" />
            </Button>
          </section>
        </div>
      </div>
    </VStepperHeader>
    <Card class="bg-grey-background px-lg-20 py-lg-12 h-100">
      <VStepperWindow>
        <VStepperWindowItem :value="steps[0].value">
          <Delivery ref="checkoutDeliveryStep" v-model:loading="loading" v-model:customer="customer" :totalQuantityForAllowedCheckoutItems="totalQuantityForAllowedCheckoutItems" @nextStep="triggerNextStep(steps[0], steps[1])" />
        </VStepperWindowItem>
        <VStepperWindowItem :value="steps[1].value">
          <Payment ref="checkoutPaymentStep" v-model:loading="loading" :strapi="strapi" :customer="customer" @previousStep="triggerPreviousStep(steps[1], steps[0])" @nextStep="triggerNextStep(steps[1], steps[2])" />
        </VStepperWindowItem>
        <VStepperWindowItem :value="steps[2].value">
          <Confirmation ref="checkoutConfirmationStep" v-model:loading="loading" :strapi="strapi" />
        </VStepperWindowItem>
      </VStepperWindow>
    </Card>
  </VStepper>
</template>

<script>
import Delivery from './components/Delivery.vue';
import Payment from './components/Payment.vue';
import Confirmation from './components/Confirmation.vue';

export default {
  name: 'CheckoutIndex',
  components: { Delivery, Payment, Confirmation },
  data() {
    return {
      loading: false,
      modelValue: null,
      steps: [
        { label: 'Delivery', value: 1, completed: false },
        { label: 'Payment', value: 2, completed: false },
        { label: 'Confirmation', value: 3, completed: false }
      ],
      ...this.$controller.data
    };
  },
  computed: {
    activeStep() {
      return this.$find(this.steps, step => step.value == this.modelValue);
    },
    isOnLastStep() {
      return this.$every(this.steps, step => step.completed);
    }
  },
  watch: {
    modelValue(newVal, oldVal) {
      this.updateURLParameter('step', this.activeStep.label.toLowerCase());
    }
    // steps: {
    //   handler(newStep, oldStep) {
    //     console.log(newStep);
    //     if (newStep.value == 3) {
    //       console.log('Setting last step to completed');
    //       newStep.completed = true;
    //     }
    //   },
    //   deep: true
    // }
  },
  created() {
    if (this.getURLParameter('step') == 'confirmation') {
      this.triggerLastStep();
    }
  },
  mounted() {
    // console.log(this.getURLParameter('return'));
    // window.addEventListener('beforeunload', this.handleBeforeUnload);
    // setTimeout(() => {
    //   this.triggerNextStep(this.steps[0], this.steps[1]);
    // }, 2000);
  },
  unmounted() {
    // window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    // async handleBeforeUnload() {
    //   const response = await this.post('/checkout/clear-session/');
    //   if (!response) {
    //     console.log('Error on clear session');
    //   }
    // },
    triggerLastStep() {
      this.$each(this.steps, step => (step.completed = true));
      this.modelValue = this.$last(this.steps).value;
      this.$nextTick(() => {
        this.$refs.checkoutConfirmationStep.onNavigatedTo();
      });
    },
    triggerNextStep(currentStep, nextStep) {
      if (nextStep.value > currentStep.value) {
        currentStep.completed = true;
        this.modelValue = nextStep.value;
        this.$nextTick(() => {
          switch (nextStep.value) {
            case 2:
              this.$refs.checkoutPaymentStep.onNavigatedTo();
              break;
            case 3:
              this.steps[2].completed = true;
              this.$refs.checkoutConfirmationStep.onNavigatedTo();
              break;
          }
        });
      } else {
        this.$snackbar.error('Cannot navigate to this step, please reload and try again');
      }
    },
    triggerPreviousStep(currentStep, previousStep) {
      if (previousStep.value < currentStep.value) {
        this.modelValue = previousStep.value;
        switch (previousStep.value) {
          case 1:
            this.$refs.checkoutDeliveryStep.onNavigatedBack();
            break;
        }
      } else {
        this.$snackbar.error('Cannot navigate to this step, please reload and try again');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-stepper {
  :deep(.v-stepper-header) {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    @include is-screen-md() {
      padding: 0;
      height: 80px;
    }

    .v-stepper-section-wrapper {
      flex-direction: column;
      @include is-screen-md() {
        flex-direction: row;
        justify-content: space-between;
      }

      @include max-screen-sm() {
        .v-stepper-section-2 {
          display: flex;
          width: 100%;
          justify-content: space-between;
          .v-stepper-item {
            padding-left: 0;
            padding-right: 0;
          }
          .v-stepper-item:nth-of-type(2) {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
  :deep(.v-stepper-window) {
    margin: 0;
  }
}
</style>
