<template>
  <Card v-scroll-lock="dialog" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
    <header class="d-flex flex-column">
      <div class="d-flex">
        <VIcon :icon="strapi.SectionIcon" size="25px" class="mr-3" />
        <h6 class="paragraph-large font-weight-regular">{{ strapi.SectionTitle }}</h6>
      </div>
    </header>
    <ExpansionPanels>
      <ExpansionPanel v-for="deliveryAddress in deliveryAddresses" :key="deliveryAddress.id" :title="deliveryAddress.fullAddress" :icon="strapi.AddressIcon" :disabled="editingIsDisabled">
        <DeliveryAddress v-model:loading="loading" :model-value="deliveryAddress" :strapi="strapi" />
      </ExpansionPanel>
    </ExpansionPanels>
    <AddDeliveryAddress @onUpdateDialog="dialog = $event" />
  </Card>
</template>

<script>
import ExpansionPanels from '@Components/ExpansionPanels.vue';
import ExpansionPanel from '@Components/ExpansionPanel.vue';
import DeliveryAddress from '@Views/account/components/DeliveryAddresses/DeliveryAddress.vue';
import AddDeliveryAddress from '@Views/account/components/DeliveryAddresses/AddDeliveryAddress.vue';
import singleSectionEditingHandling from '@Mixins/account/singleSectionEditingHandling';

export default {
  name: 'DeliveryAddresses',
  components: { ExpansionPanels, ExpansionPanel, AddDeliveryAddress, DeliveryAddress },
  mixins: [singleSectionEditingHandling],
  props: {
    modelValue: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      deliveryAddresses: this.modelValue,
      loading: false
    };
  }
};
</script>

<style lang="scss" scoped></style>
