<template>
  <Card class="bg-grey-background">
    <div class="container">
      <div class="grid">
        <Card class="grid-item -col-span-12 -col-span-lg-6 d-flex justify-center align-center flex-column" :class="[{ 'pa-5': isMobile }, { 'px-10 py-40': isNotMobile }]">
          <div class="d-flex flex-column align-center justify-center h-100 w-100">
            <VIcon icon="far fa-check-circle" color="primary" size="56" />
            <p class="paragraph-large -no-margin mt-10">Success!</p>
            <p class="paragraph-large">Thank’s for your order</p>
            <Button class="bg-secondary w-100" color="white" href="/products/">Back to the store</Button>
          </div>
        </Card>
        <Card class="grid-item -col-span-12 -col-span-lg-6" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
          <header class="d-flex justify-space-between">
            <div class="d-flex align-center">
              <VIcon icon="far fa-receipt" size="24" class="mr-3" />
              <p class="paragraph-large -no-margin">Receipt</p>
            </div>
            <div class="d-flex align-center">
              <p class="paragraph-small -no-margin mr-4">Print</p>
              <a class="ts-link-underline" target="_blank" :href="`/order/order_confirmation/${order.id}/`"><VIcon icon="far fa-print" color="primary" size="24" /></a>
            </div>
          </header>
        </Card>
      </div>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'Confirmation',
  props: {
    strapi: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      order: {},
      expandedReceipt: false
    };
  },
  methods: {
    async onNavigatedTo() {
      await this.getOrderDetails();
    },
    async getOrderDetails() {
      const response = await this.get('/checkout/get-order-details/', { orderNumber: this.getURLParameter('orderNumber') });
      if (!response) {
        return;
      }
      this.order = response;
      this.expandedReceipt = [0];
    }
  }
};
</script>
