<template>
  <Card class="bg-grey-background">
    <div class="container">
      <Form :disabled="loading" @submit.prevent="validateAndGoToPayment">
        <div class="grid">
          <Card class="grid-item -col-span-12 -col-span-lg-6 -col-span-xl-4" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
            <header class="d-flex">
              <VIcon icon="far fa-location-dot" size="24px" class="mr-3" />
              <h6 class="paragraph-large font-weight-regular">Delivery address</h6>
            </header>
            <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
            <div>
              <RadioGroup v-model="selectedCustomerDeliveryAddress">
                <div v-for="(customerDeliveryAddress, index) in customer.customerDeliveryAddresses" :key="customerDeliveryAddress.id" class="w-100">
                  <Radio class="align-baseline" :value="customerDeliveryAddress" name="checkout.selectedCustomerDeliveryAddress">
                    <template #label>
                      <p v-markdown="customerDeliveryAddress.formatted" class="-no-margin" />
                    </template>
                  </Radio>
                  <VDivider v-if="customer.customerDeliveryAddresses[index + 1]" class="my-6" />
                </div>
              </RadioGroup>
              <!-- <VDialog v-model="dialog" persistent width="1024">
                    <template #activator="{ props }">
                      <Button color="black" v-bind="props" class="d-flex justify-end">
                        <VIcon :icon="strapi.EditButtonIcon" class="text-primary mr-2" />
                        {{ strapi.EditButtonLabel }}
                      </Button>
                    </template> -->
              <!-- <EditDialog v-if="dialog" :strapi="editDialog" :closeModal="closeModal" :onSave="onSave" /> -->
              <!-- </VDialog> -->
            </div>
          </Card>
          <div class="grid-item -col-span-12 -col-span-lg-6 -col-span-xl-8">
            <Card :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
              <header class="d-flex">
                <VIcon icon="far fa-truck" size="24px" class="mr-3" />
                <h6 class="paragraph-large font-weight-regular">Select freight options</h6>
              </header>
              <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
              <div class="d-flex flex-column">
                <template v-if="PartialDeliveryIsAvailable">
                  <VRow>
                    <VCol cols="12" xl="6" class="pr-xl-5">
                      <label for="checkoutPartialDelivery">* Partial delivery or full delivery</label>
                      <RadioGroup id="checkoutIsPartialDelivery" v-model="isPartialDelivery" name="checkout.isPartialDelivery" :disabled="loading" hint="In case of partial delivery, additional freight costs will be charged." persistentHint>
                        <Radio :value="true" label="Partial delivery" />
                        <Radio :value="false" label="Deliver order in full" />
                      </RadioGroup>
                    </VCol>
                    <VCol cols="12" xl="6" class="pl-xl-5">
                      <p class="paragraph -no-margin">The following products can't be delivered in the requested quantity:</p>
                      <a v-for="cartItem in cartItemsNotAvailableInRequestedQuantity" :key="cartItem" :href="cartItem.articleVariant.route.publicShow" class="d-block font-weight-bold" aria-label="go to product page">{{ cartItem.article.name }}</a>
                    </VCol>
                  </VRow>
                  <VDivider class="my-6" />
                </template>
                <template v-if="partialOrFullDeliveryHasBeenSelectedOrNotAvailable">
                  <VRow>
                    <VCol cols="12" xl="6" class="pr-xl-5">
                      <label for="checkoutFreightMethod">* Freight method</label>
                      <Select id="checkoutFreightMethod" v-model="selectedFreightMethod" :items="customer.shop.freightMethods" :disabled="loading" :itemTitle="freightMethod => `${freightMethod.customer_description} (${freightMethod.code})`" returnObject name="checkout.delivery.freightMethod" />
                    </VCol>
                    <VCol cols="12" xl="6" class="pl-xl-5">
                      <p class="paragraph -no-margin">{{ selectedFreightMethod?.customer_extended_description }}</p>
                    </VCol>
                  </VRow>
                  <VDivider class="my-6" />
                  <VRow>
                    <VCol cols="12" xl="6" class="pr-xl-5">
                      <label for="checkoutFreightCompany">* Freight company</label>
                      <Select id="checkoutFreightCompany" v-model="selectedFreightCompany" :disabled="$isBlank(selectedFreightMethod) || loading" :items="freightCompanies" itemTitle="name" returnObject name="checkout.delivery.freightCompany" />
                    </VCol>
                    <VCol cols="12" xl="6" class="pl-xl-5">
                      <p class="paragraph -no-margin">{{ freightCompanyServiceCodeDescription }}</p>
                    </VCol>
                  </VRow>
                  <VDivider class="my-6" />
                  <VRow>
                    <VCol cols="12" xl="6" class="pr-xl-5">
                      <label for="checkoutFreightCompanyServiceCodes">* Freight service</label>
                      <Select id="checkoutFreightCompanyServiceCodes" v-model="selectedFreightServiceCode" :disabled="$isBlank(selectedFreightCompany) || loading" :items="freightCompanyServiceCodes" itemTitle="code" returnObject name="checkout.delivery.freightCompanyServiceCode" />
                    </VCol>
                    <VCol v-if="isFCA" cols="12" xl="6" class="pl-xl-5">
                      <label for="checkoutFreightCompanyAccountNumber">* Account number</label>
                      <TextField id="checkoutFreightCompanyAccountNumber" v-model="freightCompanyAccountNumber" name="checkout.delivery.freightCompanyAccountNumber" />
                    </VCol>
                  </VRow>
                  <VDivider class="my-6" />
                  <VRow>
                    <VCol cols="12" xl="6">
                      <label for="checkoutPONumber">PO number</label>
                      <TextField id="checkoutPONumber" v-model="poNumber" name="checkout.delivery.poNumber" hint="If an associated purchase order exists, please enter the PO number here." />
                    </VCol>
                  </VRow>
                  <VDivider class="my-6" />
                </template>
                <VRow>
                  <VCol cols="12" class="d-flex justify-space-between">
                    <div>
                      <template v-if="estimatedDeliveryCostWithCurrency">
                        <span class="d-block paragraph -no-margin">Delivery Cost</span>
                        <span>{{ $moneyFormat(estimatedDeliveryCostWithCurrency.cost, estimatedDeliveryCostWithCurrency.currency.code) }}</span>
                      </template>
                    </div>
                    <Button iconButton class="bg-accent" color="text-white" :disabled="allFieldsNotSelected || loading" :loading="loading" type="submit">
                      Continue
                      <VIcon icon="fas fa-chevron-right" />
                    </Button>
                  </VCol>
                </VRow>
              </div>
            </Card>
          </div>
        </div>
      </Form>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'Delivery',
  props: {
    customer: {
      type: Object,
      required: true
    },
    totalQuantityForAllowedCheckoutItems: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:loading', 'nextStep'],
  data() {
    return {
      cartItemsNotAvailableInRequestedQuantity: [],
      freightCompanies: [],
      freightCompanyServiceCodes: [],
      selectedCustomerDeliveryAddress: this.$first(this.customer.customerDeliveryAddresses),
      isPartialDelivery: null,
      poNumber: null,
      selectedFreightMethod: null,
      // selectedFreightMethod: this.$first(this.customer.shop.freightMethods),
      selectedFreightCompany: null,
      selectedFreightServiceCode: null,
      freightCompanyAccountNumber: null,
      estimatedDeliveryCostWithCurrency: null
      // dialogId: this.$uuid()
    };
  },
  computed: {
    allFieldsNotSelected() {
      if (this.PartialDeliveryIsAvailable && this.$isBlank(this.isPartialDelivery)) {
        return true;
      }

      if (this.isFCA && this.$isBlank(this.freightCompanyAccountNumber)) {
        return true;
      }
      return this.$isBlank(this.selectedCustomerDeliveryAddress) || this.$isBlank(this.selectedFreightMethod) || this.$isBlank(this.selectedFreightCompany) || this.$isBlank(this.selectedFreightServiceCode);
    },
    PartialDeliveryIsAvailable() {
      return this.$isNotEmpty(this.cartItemsNotAvailableInRequestedQuantity) && this.totalQuantityForAllowedCheckoutItems > 1;
    },
    partialOrFullDeliveryHasBeenSelectedOrNotAvailable() {
      return this.isPartialDelivery !== null || !this.PartialDeliveryIsAvailable;
    },
    isFCA() {
      return this.selectedFreightMethod?.code === 'FCA';
    },
    freightCompanyServiceCodeDescription() {
      if (!this.selectedFreightServiceCode) return;
      return this.customer.shop.company.code === 'AB' ? this.selectedFreightServiceCode.description : this.selectedFreightServiceCode.description_inc;
    }
  },
  watch: {
    async isPartialDelivery() {
      if (this.partialOrFullDeliveryHasBeenSelectedOrNotAvailable) {
        await this.getEstimatedDeliveryCost();
      }
    },
    async selectedCustomerDeliveryAddress() {
      await this.getFreightCompanies();
    },
    selectedFreightMethod: {
      async handler(val) {
        await this.getFreightCompanies();
      }
      // immediate: true
    },
    async selectedFreightServiceCode() {
      this.freightCompanyAccountNumber = null;
      if (this.partialOrFullDeliveryHasBeenSelectedOrNotAvailable) {
        await this.getEstimatedDeliveryCost();
      }
    },
    freightCompanies(value) {
      this.selectedFreightCompany = this.$first(value);
    },
    async selectedFreightCompany() {
      await this.getFreightCompanyServiceCodes();
      if (this.isFCA) {
        await this.getFreightCompanyAccountNumber();
      }
    },
    freightCompanyServiceCodes() {
      this.selectedFreightServiceCode = this.$first(this.freightCompanyServiceCodes);
    }
  },
  async created() {
    // this.$mitt.on(`dialog:cancel:${this.dialogId}`, () => {
    //   this.$redirect('/');
    // });
    this.selectedFreightMethod = this.$first(this.customer.shop.freightMethods);
    await this.getCartItemsNotAvailableInRequestedQuantity();
    // if (this.$isNotEmpty(this.cartItemsNotAvailableInRequestedQuantity)) {
    //   this.$dialog.warning({ title: 'Warning', body: 'This is a warning message' }, { id: this.dialogId, persistent: true });
    // }
  },
  methods: {
    async getCartItemsNotAvailableInRequestedQuantity() {
      this.$emit('update:loading', true);
      const response = await this.get('/checkout/get-cart-items-not-available-in-requested-quantity/');
      if (!response) {
        this.$emit('update:loading', false);
        return;
      }
      this.$emit('update:loading', false);
      this.cartItemsNotAvailableInRequestedQuantity = response;
    },
    async getFreightCompanies() {
      this.$emit('update:loading', true);
      const response = await this.get('/checkout/get-freight-companies/', { customerDeliveryAddressId: this.selectedCustomerDeliveryAddress.id, freightMethodId: this.selectedFreightMethod.id });
      if (!response) {
        this.$emit('update:loading', false);
        return;
      }
      this.$emit('update:loading', false);
      this.freightCompanies = response;
    },
    async getFreightCompanyServiceCodes() {
      this.$emit('update:loading', true);
      const response = await this.get('/checkout/get-freight-company-service-codes/', { freightCompanyId: this.selectedFreightCompany.id, customerDeliveryAddressId: this.selectedCustomerDeliveryAddress.id, freightMethodId: this.selectedFreightMethod.id });
      if (!response) {
        this.$emit('update:loading', false);
        return;
      }
      this.$emit('update:loading', false);
      this.freightCompanyServiceCodes = response;
    },
    async getFreightCompanyAccountNumber() {
      this.$emit('update:loading', true);
      const response = await this.get('/checkout/get-freight-company-account-number/', { freightCompanyId: this.selectedFreightCompany.id });
      if (!response) {
        this.$emit('update:loading', false);
        return;
      }
      this.$emit('update:loading', false);
      this.freightCompanyAccountNumber = response;
    },
    async getEstimatedDeliveryCost() {
      this.estimatedDeliveryCostWithCurrency = null;
      this.$emit('update:loading', true);
      const response = await this.get('/checkout/get-estimated-delivery-cost/', { freightCompanyServiceCodeId: this.selectedFreightServiceCode.id, freightCompanyAccountNumber: this.freightCompanyAccountNumber, freightCompanyId: this.selectedFreightCompany.id, isPartialDelivery: this.isPartialDelivery });
      if (!response) {
        this.$emit('update:loading', false);
        return;
      }
      this.$emit('update:loading', false);
      this.estimatedDeliveryCostWithCurrency = response;
    },
    async validateAndGoToPayment() {
      await this.getEstimatedDeliveryCost();
      if (this.allFieldsNotSelected || this.loading) return;
      this.$emit('update:loading', true);
      const response = await this.post('/checkout/validate/delivery/', { poNumber: this.poNumber });
      if (!response) {
        this.$emit('update:loading', false);
        return;
      }
      this.$emit('update:loading', false);
      this.$emit('nextStep');
    },
    async onNavigatedBack() {
      await this.getCartItemsNotAvailableInRequestedQuantity();
      if (this.PartialDeliveryIsAvailable) {
        this.estimatedDeliveryCostWithCurrency = null;
        if (this.isPartialDelivery != null) {
          await this.getEstimatedDeliveryCost();
        }
      } else {
        this.isPartialDelivery = null;
        await this.getEstimatedDeliveryCost();
      }
    }
  }
};
</script>
