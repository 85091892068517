import { ref, onBeforeMount } from 'vue';

export function useWindowTechship() {
  const data = ref(null);

  const updateTechship = () => {
    data.value = window.Techship;
  };

  onBeforeMount(() => {
    updateTechship();
  });

  return {
    data
  };
}
