<template>
  <footer ref="footer" class="position-relative">
    <span ref="overlay" class="animation-overlay d-block w-100 h-100" />
    <Card class="container bg-secondary mt-20">
      <div class="grid">
        <div class="grid-item -col-span-12 -col-span-lg-4 text-center text-lg-left">
          <h2>{{ footer.Title }}</h2>
          <p class="paragraph -no-margin">{{ footer.Description }}</p>
        </div>
        <div class="grid-item -col-span-12 -col-span-lg-8">
          <NewsletterSubscriptionForm :key="subscriptionFormKey" class="mt-lg-14" :strapi="footer.SubscriptionForm" @onSuccessfulSubscription="subscriptionFormKey += 1" />
        </div>
      </div>
      <div class="d-flex bg-secondary justify-space-between mt-10 gap-24" :class="{ 'flex-column': isMobile }">
        <CategoryList v-for="(item, index) in items" :key="index" class="category-list-item" :title="item.title" :items="item.children" icon="fa-solid fa-chevron-right" :isLarge="index < 2" />
      </div>
      <div class="d-flex" :class="{ 'align-center': isMobile, 'justify-space-between': !isMobile }">
        <div class="d-flex flex-column w-100">
          <div class="mt-10 d-flex align-center mb-7 w-100">
            <a :href="footer.LinkedIn.Href" aria-label="Link to our LinkedIn"><VIcon :icon="footer.LinkedIn.Icon" size="large" class="mr-3 rounded-lg py-6 px-6" /></a>
            <p class="mb-0">{{ footer.LinkedIn.Title }}</p>
          </div>
          <div class="d-flex align-center flex-wrap flex-gap-y">
            <VList class="bg-secondary d-flex align-center" :class="{ 'flex-wrap': isMobile }" aria-label="List of links to various pages">
              <VListItem v-for="Link in footer.Links" :key="Link.id" class="pl-0 min-h-0px">
                <div class="d-flex">
                  <a :href="Link.Href" :aria-label="`Go to ${Link.Title} page`" role="option">
                    {{ Link.Title }}
                  </a>
                  <div v-if="Link.BarBehind === 'Yes'" class="ml-3 text-grey align-self-center">|</div>
                </div>
              </VListItem>
            </VList>
            <div class="text-grey" :class="{ 'pl-0': isMobile }">
              Copyright &copy; {{ $dayjs().year() }} Techship&reg;
              <span class="ml-2 mr-2 d-inline">|</span>
              Sweden
            </div>
          </div>
        </div>
        <div class="d-none d-md-flex bg-white justify-center align-center mt-10 h-190px min-w-400px rounded-t-lg">
          <a href="/" aria-label="Go to homepage" class="d-flex"><Logo alt="Techship Logo - Click to return to the homepage" width="240px" /></a>
        </div>
      </div>
    </Card>
  </footer>
</template>

<script>
import CategoryList from '@Components/CategoryList.vue';
import NewsletterSubscriptionForm from '@Components/NewsletterSubscriptionForm.vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
  name: 'Footer', // eslint-disable-line
  components: { CategoryList, NewsletterSubscriptionForm },
  data() {
    return {
      loading: false,
      navigation: this.$dom.application.navigation,
      footer: this.$dom.application.footer,
      items: [],
      subscriptionFormKey: 0,
      bodyHeight: 0,
      ctx: null,
      resizeObserver: null
    };
  },
  mounted() {
    this.detectBodyScrollHeightChange();
    this.ctx = this.scrollAnimation(this.$refs.footer);
    window.addEventListener('beforeunload', this.cleanupOnUnload);
  },
  created() {
    this.$each(this.navigation, (value, index) => {
      if (this.$isEmpty(value.children)) return;
      this.$each(value.children, (item, index) => {
        this.items.push(item);
      });
    });
  },
  beforeUnmount() {
    // In case Vue lifecycle hooks do fire in some navigation scenarios
    this.cleanupOnUnload();
    window.removeEventListener('beforeunload', this.cleanupOnUnload);
  },
  methods: {
    cleanupOnUnload() {
      // Disconnect ResizeObserver
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
        this.resizeObserver = null; // Clear reference
      }

      // Revert ScrollTrigger context
      if (this.ctx) {
        this.ctx.kill();
        this.ctx = null; // Clear reference
      }
    },
    detectBodyScrollHeightChange() {
      this.resizeObserver = new ResizeObserver(entries => {
        const rect = entries[0].contentRect;
        const currentHeight = rect.height;

        if (Math.abs(currentHeight - this.bodyHeight) < 1) {
          // If the change in height is negligible, do not trigger refresh
          return;
        }

        this.bodyHeight = currentHeight; // Update stored height
        ScrollTrigger.refresh(); // Trigger the refresh only if a significant height change occurs
      });

      this.resizeObserver.observe(document.body);
    },
    scrollAnimation(target) {
      return gsap.context(() => {
        gsap.set(target, { y: -target.scrollHeight / 5 });

        const uncover = gsap.timeline({ paused: true });

        uncover.to(target, { y: 0, ease: 'none' });

        const endPos = window.innerHeight * 0.65;

        const st = ScrollTrigger.create({
          trigger: 'main',
          start: `bottom bottom`,
          end: `+=${endPos}`,
          animation: uncover,
          scrub: true,
          invalidateOnRefresh: true
        });

        gsap.to(this.$refs.overlay, {
          opacity: 0,
          scrollTrigger: {
            trigger: 'main',
            start: `bottom bottom`,
            end: '+=50%',
            toggleActions: 'play none reverse none',
            scrub: true
          }
        });

        ScrollTrigger.refresh();

        return () => st?.kill();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-icon {
  background-color: rgba($color-white, 0.1) !important;
}

.animation-overlay {
  background: rgba($color-black, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

.flex-gap-y {
  row-gap: 24px;
}

.category-list-item {
  flex-grow: 0;
  flex-shrink: 0;
}

.category-list-item:nth-child(1),
.category-list-item:nth-child(2) {
  flex-basis: calc(32% - 24px);
}

.category-list-item:nth-child(3),
.category-list-item:nth-child(4) {
  flex-basis: calc(18% - 24px);
}
</style>
