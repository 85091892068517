<template>
  <div class="heroDark bg-secondary">
    <div class="gradient-light rounded-circle" />
    <DotGridSvg class="dot-grid" />
    <div class="position-relative pt-20 pb-10 pb-md-20 z-index-10">
      <div class="container">
        <div class="grid w-100">
          <div class="mr-4 grid-item -col-span-12 -col-span-lg-8">
            <h1 class="h1 text-white bold w-100">{{ strapi?.Heading }}</h1>
            <!-- <div :class="{ 'expandable max-h-100px overflow-y-hidden': strapi.MakeDescriptionExpandable && !expanded, '-expanded': expanded }">
              <p v-if="strapi.Description" class="preamble -no-margin max-w-730px max-w-lg-900px description">{{ strapi.Description }}</p>
            </div> -->
            <div :class="getDescriptionClass">
              <p v-if="strapi?.Description" class="preamble -no-margin max-w-730px max-w-lg-900px description overflow-hidden">{{ strapi.Description }}</p>
            </div>
            <Button v-if="strapi?.MakeDescriptionExpandable" textButton class="mt-4 expand-button paragraph-large" :class="{ '-expanded': expanded }" @click="toggleExpand">{{ expanded ? expandLabels.close : expandLabels.open }}</Button>
          </div>
          <div class="h-100 grid-item -col-span-12 -col-span-lg-4 d-flex justify-lg-end align-lg-end">
            <Button v-if="strapi?.ShowSubscriptionFormButton" class="bg-primary min-w-180px" color="white" @click="scrollToFooter">{{ strapi.SubscribeButtonLabel }}</Button>
            <Button v-else-if="strapi?.Button && strapi.Button.Href" :href="strapi.Button.Href" class="bg-primary">
              {{ strapi.Button.Label }}
            </Button>
          </div>
        </div>
      </div>
      <div ref="overlay" class="overlay" :class="{ visible: isOverlayVisible }" />
      <div class="container">
        <div v-if="image" ref="image" class="rounded-lg mt-20 overflow-hidden">
          <VResponsive :aspect-ratio="image.width / image.height">
            <VImg :src="image.url" class="rounded-0" eager />
          </VResponsive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DotGridSvg from '../svgs/dot-grid.svg';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
  name: 'HeroDark',
  components: { DotGridSvg },
  props: {
    strapi: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      assetHost: this.$dom.application.assetHost,
      isOverlayVisible: false,
      expanded: false,
      expandLabels: {
        open: 'View more',
        close: 'View less'
      }
    };
  },
  computed: {
    getDescriptionClass() {
      return {
        'expandable h-100px': this.strapi?.MakeDescriptionExpandable && !this.expanded,
        // 'expandable h-100px overflow-y-hidden': this.strapi.MakeDescriptionExpandable && !this.expanded,
        '-expanded': this.expanded
      };
    },
    image() {
      if (this.strapi?.Image?.formats?.large && this.strapi.Image.size > 5000) {
        return this.strapi.Image?.formats?.large;
      }
      return this.strapi?.Image;
    }
  },
  mounted() {
    this.scrollAnimation();
    ScrollTrigger.refresh();
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
      // const descriptionElement = this.$el.querySelector('.description');
      // const fullHeight = this.expanded ? descriptionElement.scrollHeight : '100px';

      // gsap.to(descriptionElement, {
      //   duration: 1,
      //   maxHeight: fullHeight,
      //   ease: 'power1.out'
      // });
      // setTimeout(() => {
      //   // this.expanded = !this.expanded;
      //   console.log(this.expanded);
      //   if (this.expanded) {
      //     descriptionElement.classList.remove('collapse-finished');
      //     descriptionElement.classList.add('expand-finished');
      //   } else {
      //     descriptionElement.classList.remove('expand-finished');
      //     descriptionElement.classList.add('collapse-finished');
      //   }
      // }, 1000);
    },
    scrollToFooter() {
      const footer = document.querySelector('footer');
      if (footer) {
        gsap.to(window, {
          duration: 1,
          scrollTo: footer,
          ease: 'Power1.easeInOut',
          onComplete: () => {
            this.$mitt.emit('scrollToFooterNewsletterSubscriptionForm');
          }
        });
      }
    },
    scrollAnimation() {
      if (!this.$refs.image) return;

      const mm = gsap.matchMedia();
      mm.add(
        {
          isDesktop: `(min-width: 1904px)`,
          isTabletLandscape: `(min-width: 1264px)`,
          isTabletPortrait: `(min-width: 960px)`,
          isMobile: `(max-width: 959px)`
        },
        context => {
          const imageWidth = this.$refs.image.clientWidth;
          const maxWidth = Math.round(imageWidth * 1.2);

          gsap.to(this.$refs.image, {
            width: maxWidth < window.innerWidth ? maxWidth : '100vw',
            y: -200,
            x: maxWidth >= window.innerWidth ? -(window.innerWidth - imageWidth) / 2 : -(maxWidth - imageWidth) / 2,
            marginBottom: maxWidth < window.innerWidth ? -200 : window.innerWidth < 960 ? -240 : -280,
            borderRadius: maxWidth >= window.innerWidth && '0px',
            ease: 'power4.inOut',
            scrollTrigger: {
              trigger: this.$refs.image,
              scrub: true,
              start: 'top top+=380px',
              end: 'top top',
              toggleActions: 'play none reverse none',
              invalidateOnRefresh: true,
              onEnter: () => {
                this.isOverlayVisible = true;
                gsap.to(this.$refs.overlay, {
                  opacity: 1
                });
              },
              onLeaveBack: () => {
                gsap.to(this.$refs.overlay, {
                  opacity: 0,
                  onComplete: () => {
                    this.isOverlayVisible = false;
                  }
                });
              }
            }
          });
          return () => {};
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.expandable {
  position: relative;

  .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; // number of lines to show
    text-overflow: ellipsis;
  }

  .collapse-finished {
    // max-height: 100px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; // number of lines to show
    text-overflow: ellipsis;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
  }

  &.-expanded:after {
    content: none;
  }
}

.-expanded {
  height: fit-content;
  // height: 224px;
  .description {
    display: block;
    -webkit-line-clamp: unset;
  }
}
.expand-button {
  &:hover {
    border-color: $color-primary;
  }
}

.heroDark {
  position: relative;
  overflow: hidden;
  padding-top: $menu-height-desktop;
}

.dot-grid {
  position: absolute;
  right: -48px;
  top: 116px;
  opacity: 0.1;
  width: 70%;

  @include is-screen-lg() {
    width: 488px;
  }
}
.gradient-light {
  position: absolute;
  width: 100vw;
  height: 100vw;
  right: -80px;
  top: 0;
  transform: translateY(-50%);
  background: radial-gradient(50% 50% at 50% 50%, rgba(245, 158, 0, 0.28) 0%, rgba(245, 158, 0, 0) 100%);

  @include is-screen-md() {
    width: 80vw;
    height: 80vw;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  display: none;
}

.visible {
  display: block;
}
</style>
