<template>
  <div class="d-flex flex-column w-100 align-center align-lg-end">
    <VForm v-model="valid" class="d-flex flex-lg-row w-100 pb-5" :disabled="loading" @submit.prevent="checkValidAndStartCaptcha">
      <div class="newsletter-input-container d-flex gap-40">
        <TextField ref="name" v-model="name" name="newsletter.name" class="h-40px w-100 max-w-390px w-lg-290px max-w-lg-290px" :placeholder="strapi.NamePlaceholder" color="!white" />
        <EmailTextField v-model="email" type="email" name="newsletter.email" class="h-40px w-100 max-w-390px w-lg-290px max-w-lg-290px" :placeholder="strapi.EmailPlaceholder" />
      </div>
      <Captcha ref="captcha" @verify="submit" @expired="resetCaptcha" />
      <Button class="mt-8 mt-lg-0 ml-lg-4 w-180px bg-primary" color="white" :loading="loading" :disabled="loading" type="submit">
        {{ strapi.ButtonLabel }}
        <VIcon :icon="strapi.ButtonIcon" size="x-small" class="ml-2" />
      </Button>
    </VForm>
    <p class="caption -no-margin text-grey text-right" :class="{ 'd-flex justify-center flex-wrap': isMobile }">
      <span class="mr-1">{{ strapi.ReCaptchaText }}</span>
      <a href="https://policies.google.com/privacy" aria-label="go to googles privacy policy" class="text-grey">{{ strapi.PrivacyPolicyLink }}</a>
      <span class="mr-1 ml-1">{{ strapi.AndText }}</span>
      <a href="https://policies.google.com/terms" aria-label="go to googles policy terms" class="text-grey">{{ strapi.TermsOfServiceLink }}</a>
      <span class="ml-1">{{ strapi.ApplyText }}</span>
    </p>
  </div>
</template>

<script>
import Captcha from '@Components/Captcha.vue';

export default {
  name: 'NewsletterSubscriptionForm',
  components: { Captcha },
  emits: ['onSuccessfulSubscription'],
  data() {
    return {
      strapi: this.$dom.application.footer.SubscriptionForm,
      loading: false,
      valid: false,
      email: '',
      name: '',
      captcha: ''
    };
  },
  created() {
    this.$mitt.on('scrollToFooterNewsletterSubscriptionForm', () => {
      this.$refs.name.focus();
    });
  },
  methods: {
    async checkValidAndStartCaptcha() {
      if (!this.valid) return;
      this.loading = true;
      if (this.captcha === '') {
        this.$refs.captcha.execute();
      } else {
        await this.submit(this.captcha);
      }
    },
    async submit(captcha) {
      this.captcha = captcha;
      const response = await this.post('/newsletter/subscribe/', { newsletter: { email: this.email, name: this.name, captcha: this.captcha } });
      this.loading = false;
      if (!response) return;
      this.$emit('onSuccessfulSubscription');
    },
    resetCaptcha() {
      this.$refs.captcha.reset();
      this.captcha = '';
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-form) {
  & {
    @include max-screen-lg() {
      flex-direction: column;
      align-items: center;
    }
  }
  .v-input__details {
    padding-left: 0 !important;
  }
  .v-messages__message {
    color: $color-white !important;
    font-weight: 600;
  }
  .v-field {
    background-color: rgba($color-white, 0.1) !important;
    color: $color-white !important;
  }
}

.newsletter-input-container {
  @include max-screen-lg() {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  @include is-screen-lg() {
    justify-content: flex-end;
    flex-grow: 1;
  }
}
.v-responsive {
  @include is-screen-sm() {
    max-width: 500px;
  }
  @include is-screen-md() {
    max-width: 900px;
  }
}
</style>
