<script setup>
import HeroDark from '@Components/HeroDark.vue';
import { useWindowTechship } from "@/composables/useWindowTechship";
import { ref } from 'vue';

const { data } = useWindowTechship()
const loading = ref(false);

</script>

<template>
  <div>
    <HeroDark :strapi="data?.strapi?.Hero" />
    <PageBuilder v-if="data?.strapi.Modules" :modules="data.strapi.Modules" />
  </div>
</template>
